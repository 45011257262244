import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

const STATES = [
    'InitialiseData',
    'SelectName',
    'EnterPassword',
    'VerifyAge',
    'ChoosePassword',
    'SelectRace',
    'SelectClass',
    'SelectGender',
    'EnterEmailAddress',
    'FailedLogin',
    'SuccessfulLogin',
    'SelectStartLocation',
    'DisplayWho',
    'DisplayReview',
    'SelectAlignment',
    'Exit',
    'Error',
    'Banned',
    'Review'];


const PHASES = [
    'Start',
    'Assign',
    'Error',
    'Confirm',
    'Help',

    /**
     * When the user is prompted for a username, but may also select other options.Shel;
     */
    'StartName',
    'MSSP',
    'StartParseName',
    'ConfirmName',
    'NameInUse',
    'NameIsBad',

    'NameIsBanned',
    'EmailIsBanned',

    'PasswordEntry',
    'PasswordIncorrect',

    'CreatePassword',
    'ConfirmPassword',
    'MismatchedPasswords',
    'InvalidPassword',

    'DisplayRaceList',
    'ChooseRace',
    'RaceNotFound',
    'DisplayAdvancedRaceList',

    'DisplayDragonBreedOptions',
    'ChooseDragonBreedOptions',
    'DisplayDragonPathOptions',
    'ChooseDragonPathOptions',
    'DisplayDryadOptions',
    'ChooseDryadOptions',

    'ConfirmRace',

    'ConfirmGender',
    'EnterEmail',
    'ConfirmEmail',
    'VerifyAgePass',
    'VerifyAgeFail',
    'Banned',
    'Complete'
];

const PersonaData = ({ personaIn }) => {
    const [persona, setPersona] = useState(null);

    useEffect(() => {
        if (personaIn !== undefined)
            setPersona(personaIn);
    }, [personaIn]);

    if (persona === null) {
        return null;
    }

    const showPhase = (stateData, phase) => {
        if (stateData.phases[phase] !== undefined) {
            let phaseData = stateData.phases[phase];

            return <>
                <Row className={"pt-3"}>
                    <h6>Phase {phase}</h6>

                </Row>
                <Row>
                    <Col md={2}>
                        <strong>Next Phase: </strong>
                    </Col>
                    <Col md={2}>
                        {phaseData.nextPhase}
                    </Col>
                </Row>
                <Row>
                    {phaseData.data.map((data, dataIndex) =>
                        <>
                            <Row key={dataIndex}>
                                <span>{dataIndex+1}. {data.prompt?"Prompt ":"Entry "} = {data.text}</span>
                            </Row>
                        </>
                    )}

                </Row>
            </>
        } else
            return null;
    }


    const showState = (state) => {
        if (persona.stateData[state] !== undefined) {
            console.log('data', persona.stateData[state]);
            let stateData = persona.stateData[state];
            return (
                <>
                    <br />
                    <Row className={"pt-5"}>
                        <h5>State <strong>{stateData.state}</strong></h5>
                    </Row>
                    <Row>
                        <Col md={1}>
                            <strong>Next: </strong>
                        </Col>
                        <Col md={1}>
                            {stateData.nextState}
                        </Col>
                    </Row>
                    <Row>
                        {PHASES.map((phase, phaseIndex) =>
                            <>
                                <Row key={phaseIndex}>
                                    {showPhase(stateData, phase)}
                                </Row>
                            </>
                        )}

                    </Row>
                </>
            );
        } else
            return null;
    };

    console.log(persona);
    return (
        <Col lg={10}>
            <h4>Personas for Character Creation {persona.name}</h4>
            <Row>
                <Col md={2}>
                    <strong>Approved: </strong>
                </Col>
                <Col md={1}>
                    {persona.approved ? "Yes" : "No"}
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <strong>Initial State: </strong>
                </Col>
                <Col md={1}>
                    {persona.initialState}
                </Col>
            </Row>

            <Row>
                <Col md={2}>
                    <strong>Next State: </strong>
                </Col>
                <Col md={1}>
                    {persona.nextState}
                </Col>
            </Row>
            {STATES.map((state, index) =>
                <>
                    <Row key={index}>

                        {showState(state)}
                    </Row>
                </>
            )}

        </Col>
    );
}
export default PersonaData;