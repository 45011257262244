import { getSchematics, updateProduct } from '../../components';
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPersona, getPersonas } from '../../components/professionRequests';
import PersonaData from './PersonaData';

const PersonaEditor = (props) => {

    const [editing, setEditing] = useState(false);
    const [personas, setPersonas] = useState([]);
    const [selectedPersona, setSelectedPersona] = useState(null);

    useEffect(() => {
        getPersonas().then(data => {
            console.log(data);

            if (data.result) {
                console.log(data.personas);
                setPersonas(data.personas);
            } else
                setPersonas([]);
        });
    }, []);

    const loadPersona = (persona) => {
        console.log(`Load`, persona);
        getPersona(persona).then(data => {
            setSelectedPersona(data.persona);
            console.log(data);
        });
    }

    return (
        <Container>
            <Row>
                <h1>Personas for Character Creation</h1>
                <Col lg={2}>
                    {personas.map((persona, index) =>
                        <Row key={index} onClick={() => loadPersona(persona)}>
                            {persona}
                        </Row>
                    )}
                </Col>

                <PersonaData personaIn={selectedPersona} />
            </Row>

        </Container>
    );

}
export default PersonaEditor;