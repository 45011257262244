import React from 'react';
import { genericAuthenticatedFetch, genericAuthenticatedPut, genericAuthenticatedDelete } from './index';
import { getAuthenticatedHeader } from '../utils/FetchHeaders';
import Config from '../../../config/Config';


export async function getProfessions() {
  return genericAuthenticatedFetch(`profession/list`);
}

export async function findSchematic(searchstring) {
  return genericAuthenticatedFetch(`profession/find/${searchstring}`);
}

export async function getProducts(profession_id) {
  return genericAuthenticatedFetch(`profession/get/${profession_id}`);
}

export async function getProductList() {
  return genericAuthenticatedFetch(`profession/product/list`);
}

export async function getSchematics(profession_id, product_line_id) {
  return genericAuthenticatedFetch(`profession/get/${profession_id}/${product_line_id}`);
}

export async function getSchematicData(profession_id, product_line_id, schema_id) {
  return genericAuthenticatedFetch(`profession/get/${profession_id}/${product_line_id}/${schema_id}`);
}

export async function getSchematic(schematic_id) {
  return genericAuthenticatedFetch(`profession/schematic/get/${schematic_id}`);
}

export async function createSchematic(template) {
  return genericAuthenticatedFetch(`profession/schematic/create/${template}`);
}

export async function getSchematicList() {
  return genericAuthenticatedFetch(`profession/schematic/list`);
}

export async function searchSchematics(name, type, product, profession) {
  let options = `name=${name}`;
  options += `&type=${type}`;
  options += `&product=${product}`;
  options += `&profession=${profession}`;
  return genericAuthenticatedFetch(`profession/schematic/search?${options}`,);
}

export async function updateSchematic(schemaid, schematic) {
  return genericAuthenticatedPut(`profession/schematic/update/${schemaid}`, JSON.stringify(schematic));
}

export async function previewSchematic(schematic) {
  return genericAuthenticatedPut(`profession/schematic/preview`, JSON.stringify(schematic));
}

export async function metacraftItem(schemaid, material, level, quality, quantity) {
  let payload = {};
  payload.schemaid = schemaid;
  payload.material = material;
  payload.level = level;
  payload.quality = quality;
  payload.quantity = quantity;
  return genericAuthenticatedPut('profession/metacraft', JSON.stringify(payload));
}

export async function updateProfessionProducts(profession) {
  console.log(profession);
  return genericAuthenticatedPut(`profession/update/${profession.profession}`, JSON.stringify(profession));
}


export async function updateProduct(product_id, product, schematics) {
  //console.log('Updating Product', product_id, product, schematics);
  let payload = {};
  payload.product = product;
  payload.schematics = schematics;
  return genericAuthenticatedPut(`profession/product/update/${product_id}`, JSON.stringify(payload));
}

export async function deleteProductFromServer(product_id) {
  //console.log('Deleting Product from server', product_id);
  return genericAuthenticatedDelete(`profession/product/delete/${product_id}`);
}

export async function getPersonas() {
  return genericAuthenticatedFetch(`builder/personas/list`);
}

export async function getPersona(persona) {
  let encoded_persona = persona;
  return genericAuthenticatedFetch(`builder/personas/get/${encoded_persona}`);
}